import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Nav from "../pages/Nav.js";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>About Us</title>
                <link rel="canonical" href="https://multitectools.com/About"/>
                <meta name="title" content="Multitec Tools"/>
                <meta
                    name="description"
                    content="Wire Strippers, Screw Drivers & Micro Shear Manufacturer offered by Multitec Industries from Gurgaon, Haryana, India."
                />
            </Helmet>

            <Nav/>

            {/* Banner Section */}
            <div className="pt-12 mb-10">
                <img
                    loading="lazy"
                    className="w-full"
                    src="image/aboutBanner.webp"
                    alt="About Multitec"
                />
            </div>

            {/* Main Content */}
            <div className="sm:px-10 py-4 mx-4 border-b-2 border-black">
                <div className="px-10">
                    {/* Organisation */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Organisation:</b>
                        <br/>
                        Innovative, Inspiring.
                        <br/>
                        Our persistent effort of introduction of innovative, indigenous tools for industry and
                        agricultural sectors define the Company's legacy.
                    </p>

                    <br/>

                    {/* About Us */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">About Us:</b> With a strong vision of catering and introducing newer
                        tools keeping in pace with the time Multitec is ever ready with its team.
                    </p>

                    <br/>

                    {/* Vision */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Vision:</b> We aim to become the well-known name in tools for
                        Industries, Horticulture & Agriculture sectors.
                    </p>

                    <br/>

                    {/* Mission */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Mission:</b> Innovate, Develop, Upgrade and Manufacture tools
                        in-house for various Industries, Agriculture & Horticulture keeping pace with necessity and
                        time.

                    </p>

                    <br/>

                    {/* Our Journey */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Our Journey:</b> With one tool — Wire
                        Stripper in 1981, today Multitec manufactures over 200 kinds of
                        tools in-house across various categories.
                        <br/>
                        Today, with two plants in Gurgaon, Haryana, and Roorkee, Uttarakhand it is spread in an area
                        of <b>70,000 sq. ft.</b>
                    </p>

                    <br/>

                    {/* Milestones */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Milestones:</b>
                        <ul className="mt-2">
                            <li>
                                <b>1981 : </b> Incorporation: first company in India to launch wire strippers for
                                electronics and electrical industry.

                            </li>
                            <li>
                                <b>1984 : </b> Launched Flash Cutters, Diagonal Nippers from HCHCr for Electronic
                                Industry.

                            </li>
                            <li>
                                <b>1991 : </b> Crimping tools for the Electronic
                                Industry.
                            </li>
                            <li>
                                <b>1996 : </b> Ball type Allen keys & T-type Allen keys made out of Mo-Cr Steel.
                            </li>
                            <li>
                                <b>1998 : </b> Pneumatic Screw Driver Bits.
                            </li>
                            <li>
                                <b>2006 : </b> High-performance Stainless-Steel tools.
                            </li>
                            <li>
                                <b>2011 : </b> Screw Drivers with Hexagon rods.
                            </li>
                            <li>
                                <b>2017 : </b> Initiated development of high-quality Agri & Garden
                                tools.
                            </li>
                            <li>
                                <b>2024 : </b> Quick-fix Multi Tools System.
                            </li>
                        </ul>
                    </p>

                    <br/>

                    {/* Our Presence */}
                    <p className="font-kepler font-medium text-[17px]">
                        <b className="text-red-700">Our Presence:</b> We are India's leading tools manufacturer having
                        footprints in US, Europe, Asia and across the length and breadth of India.

                    </p>
                </div>

                {/* Amazon Link */}
                <div className="px-10 py-4 mt-5 text-center">
                    <a
                        href="https://www.amazon.in/s?me=AWPMSA9DQ9C2J&ref=sf_seller_app_share_new"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="h-[70px] object-contain transition-transform duration-200 hover:scale-75 rounded-lg"
                            src="image/Amazone.jpg"
                            alt="Buy on Amazon"
                        />
                    </a>
                </div>
            </div>

            {/* Footer Banner */}
            <div className="text-center flex justify-center mt-12 mx-5">
                <img
                    className="w-full rounded-[17px]"
                    src="mainbanner/All Tool Images Collase.png"
                    alt="All Tools"
                />
            </div>
        </>
    );
};

export default AboutUs;
