export const Products = [
    {
        id: 96,
        URLname: "Lawn-Edge-Cleaner",
        name: "Lawn Edge Cleaner",
        img: "mts-101.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-101",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Serrated pronounced edge for effortless working ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed to neatly clean and straighten lawn edges"],
    },
    {
        id: 97,
        URLname: "Small-Weeding-Cultivator",
        name: "Small Weeding Cultivator",
        img: "mts-102.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-102",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel ",
            "Duly heat-treated for durability ",
            "Tough tines to loosen soil ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Handy tool for weeding, hoeing and making trenches",
            "Suitable in gardens",
            "fields in and around bushy shrubs and plants ",
        ],
    },
    {
        id: 98,
        URLname: "Cultivator-cum-Weeder ",
        name: "Cultivator cum Weeder ",
        img: "mts-103.jpeg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-103",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            " Tough tines to loosen soil  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Sturdy tool for weeding, hoeing and making trenches",
            "Suitable in gardens, fields in and around bushy shrubs and plants",
        ],
    },
    {
        id: 99,
        URLname: "Soil-Aerator",
        name: "Soil Aerator",
        img: "mts-104.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-104",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for easy loosening and ventilation of soils",
            "Effortless use in narrow bed rows capacitating optimum supply of nutrients",
            "Back face angle of the tool head effortlessly draw through the soil",
        ],
    },
    {
        id: 100,
        URLname: "Ridge-Plough",
        name: "Ridge Plough ",
        img: "mts-105.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-105",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Intricately designed curvatures for effortless working in fields ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "For making ridges, furrows and irrigation channels in fields",
            "Easy to use for large scale vegetable growers",
        ],
    },
    {
        id: 101,
        URLname: "Garden-Tiller",
        name: "Garden Tiller",
        img: "mts-106.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-106",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough rotating tines and weeder to weed and loosen soil ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles ",
        ],
        application: [
            "Designed for weeding and breaking-up hard, compact soil into loose, broken-up  soil for easy planting",
        ],
    },
    {
        id: 102,
        URLname: "Dutch-Hoe",
        name: "Dutch-Hoe",
        img: "mts-107.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-107",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp ends ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed for weeding with minimal pressure"],
    },
    {
        id: 103,
        URLname: "Hoe-2-Prong",
        name: "Hoe-2 Prong ",
        img: "mts-108.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-108",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough 2 Prong Rake and pointed Hoe  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Prongs used for loosening the soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
            "Suitable for pulling seed grooves",
        ],
    },
    {
        id: 104,
        URLname: "Hoe-3-Prong",
        name: "Hoe-3 Prong",
        img: "mts-109.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-109",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Tough 3 Prong Rake and Hoe ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Prongs used for loosening the soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
        ],
    },
    {
        id: 105,
        URLname: "Hoe-3-Prong-HD",
        name: "Hoe-3 Prong HD",
        img: "mts-110.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-110",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Heavy Duty tough 3 Prong Rake and Hoe ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Heavy duty Prongs used for loosening the tough soil",
            "Opposite side Hoe used for hoeing-cultivating and weeding of the soil",
        ],
    },
    {
        id: 106,
        URLname: "Draw Hoe",
        name: "Draw Hoe",
        img: "mts-111.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-111",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp edge Blade",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for weeding under and between plants with its extra sharp blades",
        ],
    },
    {
        id: 107,
        URLname: "Weed-Extractor",
        name: "Weed Extractor",
        img: "mts-112.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-112",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Sharp pointed contoured knife end  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for removing deep rooted weeds from base",
            "Knife end is inserted by thrusting into the soil exerting force on foot rest. The weed is pulled out without harming the plant. ",
        ],
    },
    {
        id: 108,
        URLname: "Swoe-Weeder",
        name: "Swoe Weeder",
        img: "mts-113.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-113",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Three-way sharp edges  ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for weeding from roots by push and pull operation ",
        ],
    },
    {
        id: 109,
        URLname: "Push-Pull-Weeder",
        name: "Push Pull Weeder",
        img: "mts-114.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-114",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Two-way sharp corrugated blades ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed to weed light and sandy soils effortlessly",
            "Two-way blades cut through weeds on the pull and push stroke below the soil",
        ],
    },
    {
        id: 110,
        URLname: "Soil Rake",
        name: "Soil Rake",
        img: "mts-115.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-115",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "High strength 14 teeth Rake ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            " For clearing large field beds effortlessly",
            "For heavy duty works",
        ],
    },
    {
        id: 111,
        URLname: "Bow-Rake",
        name: "Bow Rake",
        img: "mts-116.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-116",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "High strength 16 teeth Rake ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "For clearing tough large field beds effortlessly",
            "For heavy duty works",
        ],
    },
    {
        id: 112,
        URLname: "Trowel",
        name: "Trowel",
        img: "mts-117.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-117",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Strong pointed trowel for field work ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: ["Designed for removing soil and repot plants"],
    },
    {
        id: 113,
        URLname: "Fork",
        name: "Fork",
        img: "mts-118.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-118",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of High-Grade Carbon Steel",
            "Duly heat-treated for durability ",
            "Strong Tines ",
            "Coated for long life",
            "Fitted with grip for quick-fix onto four lengths of handles",
        ],
        application: [
            "Designed for breaking-up soil, turning over new beds and moving plants with large roots ",
        ],
    },
    {
        id: 114,
        URLname: "Handle-1-25",
        name: "Handle-1.25",
        img: "mts-hdl-15.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-15",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe",
            "Coated for long life",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for easy works in small gardens and fields",
        ],
        technical: ["Length 15"],
    },
    {
        id: 115,
        URLname: "D-Grip-Handle-2-5",
        name: "D-Grip Handle 2.5",
        img: "mts-hdl-30.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-30",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe ",
            "Coated for long life",
            "D-Grip for comfortable and easy working",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for works in gardens and fields",
        ],
        technical: ["Length 30"],
    },
    {
        id: 116,
        URLname: "D-Grip-Handle-4-0",
        name: "D-Grip Handle 4.0",
        img: "mts-hdl-48.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-48",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe ",
            "Coated for long life ",
            "D-Grip for comfortable and easy working",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for works in large gardens and fields",
        ],
        technical: ["Length 48"],
    },
    {
        id: 117,
        URLname: "D-Grip-Handle-5-0",
        name: "D-Grip Handle 5.0",
        img: "mts-hdl-60.jpg",
        category: "Quick-fix Multi-tool",
        modelno: "MTS-HDL-60",
        headname: "Quick-fix Multi-tool",
        URL: "quickfix-multitools.jpeg",
        features: [
            "Made out of light weight heavy duty Aluminium pipe",
            "Coated for long life",
            "Fitted with Quick-fix system",
        ],
        application: [
            "For holding attachments MTS101 to MTS118 for very heavy works in gardens and fields",
        ],
        technical: ["Length 60"],
    },
    {
        id: 119,
        modelno: "PSA-01",
        URLname: "Anvil-Pruning-Shear-7-1-2",
        name: "Anvil Pruning Shear – 7.1/2",
        img: "PSA-01.jpg",
        features: [
            "Blade made out of stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 15mm diameter", "Overall length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 120,
        modelno: "PSA-02",
        URLname: "Anvil-Pruning-Shear-8-1-4",
        name: "Anvil Pruning Shear - 8.1/4",
        img: "PSA-02.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 121,
        modelno: "PSA-06",
        URLname: "Anvil-Pruning-Shearr-Bent-Type-8-1-4",
        name: "Anvil Pruning Shear(Bent Type) - 8.1/4",
        img: "PSA-06.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Bent handle for extra grip",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 122,
        modelno: "PSA-10",
        URLname: "Curved-Anvil-Pruning-Shear",
        name: "Curved Anvil Pruning Shear",
        img: "PSA-10.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Curved Anvil",
            "Ergonomic handles for fatigue free long duration work",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of green wood ",
            "A suitable pruning shear widely used in Apple Orchards",
        ],
        technical: ["Cutting Capacity: 15mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 123,
        modelno: "M35-Xpert",
        URLname: "High-Grip-Anvil-Pruning-Shear",
        name: "High Grip Anvil Pruning Shear ",
        img: "M35-Xpert.jpg",
        features: [
            "Blade made out of high-performance stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "High Grip ergonomic handles for excellent grip and fatigue free long duration work ",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of both dry and green wood",
            "An evergreen pruning shear for all to use at home/garden/orchard",
        ],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 220mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 124,
        modelno: "PSB-03",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-03",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-03.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Heavy duty aluminum die cast handles in bright silver fluorescent colour.",
            "Easy safety locking system",
            "Antique look",
            "A must for Gardener’s collection",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 20mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 125,
        modelno: "PSB-04",
        URLname: "By-pass-Pruning-Shear-PSB-04",
        name: "By-pass Pruning Shear",
        img: "PSB-04.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Virgin polymer light weight handles for daily use",
            "Easy safety locking system",
            "A must for Kitchen Garden use.",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 15mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 126,
        modelno: "PSB-05",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-05",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-05.jpg",
        features: [
            "Blade made out of high-performance stainless steel",
            "Double heat-treated blade for extra power",
            "Clean and precise cut does not damage and create stress to the plant",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Ergonomic handles for fatigue free long duration work",
            "Rubber handles for comfortable grip",
            "Easy safety locking system",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 127,
        modelno: "PSB-07",
        URLname: "Auto-unlock-By-pass-Pruning-Shear",
        name: "Auto-unlock By-pass Pruning Shear",
        img: "PSB-07.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant",
            "Ergonomic handles for fatigue free long duration work",
            "Bright coloured plastisol sleeves on Aluminium die cast handles",
            "Auto unlocking when held in palm and pressed ",
            "Easy safety locking system",
            "A must for Gardener’s collection",
        ],
        application: ["Pruning of green wood"],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 210mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 128,
        modelno: "PSB-08",
        URLname: "Heavy-Duty-By-pass-Pruning-Shear-PSB-08",
        name: "Heavy Duty By-pass Pruning Shear",
        img: "PSB-08.jpg",
        features: [
            "Blade made out of high-performance stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant ",
            "Ergonomic handles for fatigue free long duration work",
            "Nylon handles contoured for comfortable grip",
            "Easy safety locking system",
        ],
        application: [
            "Pruning of green wood ",
            "Widely used in Sericulture, Apple Orchards",
        ],
        technical: ["Cutting Capacity: 19mm diameter", "Overall Length: 230mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 129,
        modelno: "PSB-09",
        URLname: "By-pass-Pruning-Shear-PSB-09",
        name: "By-pass Pruning Shear",
        img: "PSB-09.jpg",
        features: [
            "Blade made out of high-grade stainless steel ",
            "Double heat-treated blade for extra power",
            "Laser beam checked cutting edge profile for homogenous sharpness",
            "Clean and precise cut does not damage and create stress to the plant ",
            "Ergonomic handles for fatigue free long duration work",
            "Sleek design",
            "Suitable for light duty work",
            "Easy safety locking system",
        ],
        application: [" Pruning of green branches"],
        technical: ["Cutting Capacity: 14mm diameter", "Overall Length: 190mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 130,
        modelno: "AHS-10",
        URLname: "Hedge-Shear-with-Aluminium-Handle",
        name: "Hedge Shear with Aluminium Handle",
        img: "AHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall Length: 580mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 131,
        modelno: "PHS-10",
        URLname: "Hedge-Shear-with-Plastic-Moulded-Handles",
        name: "Hedge Shear with Plastic Moulded Handles",
        img: "PHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Virgin Plastic moulded handles",
            "Contoured profile for comfortable grip and long duration work",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall length: 545mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 132,
        modelno: "WHS-10",
        URLname: "Hedge-Shear-with-Wooden-Handle",
        name: "Hedge Shear with Wooden Handle",
        img: "WHS-10.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Wooden Handles with contoured profile for comfortable grip in hands",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: ["Trimming of bushes", "Creating and maintaining hedges"],
        technical: ["Overall Length: 575mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 133,
        modelno: "AHS-10L",
        URLname: "Long-Handle-Aluminium-Handle-Hedge-Shear",
        name: "Long Handle Aluminium Handle Hedge Shear",
        img: "AHS-10L.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Long Handles for easy reach to far-off bushes",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: [
            "Trimming of bushes",
            "Creating and maintaining hedges",
            "Long handles trim and shape far-off bushes",
        ],
        technical: ["Overall length: 870mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 134,
        modelno: "THS-10X",
        URLname: "Telescopic-Hedge-Shear",
        name: "Telescopic Hedge Shear",
        img: "THS-10X.jpg",
        features: [
            "Blades made out of High-Grade Alloy Steel",
            "Double Heat-treated blades for toughness and retention of sharp edges",
            "Blade edges duly sharpened",
            "Anti-rust auto black surface finish of blades",
            "Telescopic – Extendable Handles for easy reach to far of bushes",
            "Light weight Handles made out of heavy-duty Aluminium pipes",
            "Comfortable rubberised PU grips",
            "Links designed to withstand load",
            "Stoppers material sustains impact load",
            "Rivetted link-handle prevents loosening of handle with link",
        ],
        application: [
            "Trimming of bushes",
            "Creating and maintaining hedges",
            "Extendable handles trim and shape far-off bushes",
        ],
        technical: ["Overall length: 840mm(approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 135,
        modelno: "AL-22",
        URLname: "Anvil-Lopper",
        name: "Anvil Lopper",
        img: "AL-22.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfort grips.",
            "Aluminum handles are corrosion free",
            "Best suitable for dead wood. Can also be used for green wood",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 30”/ 740mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 136,
        modelno: "TAL-37X",
        URLname: "Telescopic-Extra-Leverage-Anvil-Lopper",
        name: "Telescopic Extra Leverage Anvil Lopper ",
        img: "TAL-37X.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Telescopic/Extendable handles for extra leverage/reduced effort",
            "Light weight handles made out of heavy-duty Aluminum pipe with comfort grips",
            "Easy operation of extension of handles",
            "Aluminum handles are corrosion free",
            "Best suitable for dead wood. Can also be used for green wood.",
        ],
        application: [
            "Cut unwanted branches and twigs",
            "Extendable handles cut high reach branches and twigs easily",
        ],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 37”/ 950mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 138,
        modelno: "BPL-22G",
        URLname: "Geared-Bypass-Lopper",
        name: "Geared By-pass Lopper",
        img: "BPL-22G.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfort grips",
            "Aluminum handles are corrosion free",
            "Best suitable for green/live wood.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 40mm", "Overall length 30”/750mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 139,
        modelno: "BPL-37XG",
        URLname: "Telescopic-Extra-Leverage-Geared-By-pass-Lopper",
        name: "Telescopic Extra Leverage Geared By-pass Lopper",
        img: "BPL-37XG.jpg",
        features: [
            "Blade made out of SK5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes.",
            "Clean and precise cut does not damage and create stress to the plant",
            "Telescopic/Extendable handles for extra leverage.",
            "Geared for reduced effort.",
            "Light weight handles made out of heavy-duty Aluminum pipe with comfort grips.",
            "Easy operation of extension of handles",
            "Aluminum handles are corrosion free",
            "Best suitable for green/live wood.",
        ],
        application: [
            "Cut unwanted branches and twigs",
            "Extendable handles cut high reach branches and twigs easily",
        ],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: [
            "Cutting Capacity: 40mm",
            "Overall Extendable length 37”/ 970mm (approx.)",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 137,
        modelno: "BPL-14G",
        URLname: "Power-Geared-By-pass-Lopper",
        name: "Power Geared By-pass Lopper",
        img: "BPL-14G.jpg",
        features: [
            "Blade made out of SK-5 Steel",
            "Dual heat-treated blades for high cutting performance",
            "Single-edged blade slice past thick base as it closes.",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort.",
            "Fixed, light weight handles made out of heavy-duty Aluminum pipe with comfortable grips",
            "Best suitable for green/live wood.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 30mm"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 140,
        modelno: "PL15G",
        URLname: "Power-Geared-Anvil-Lopper",
        name: "Power Geared Anvil Lopper",
        img: "PL-15G.jpg",
        features: [
            "Blade made out of Stainless Steel",
            "Special heat-treated blades for high cutting performance",
            "Straight cutting edge for clear chopping/slicing at center with equally sturdy anvil as support",
            "Clean and precise cut does not damage and create stress to the plant",
            "Geared for reduced effort",
            "Fixed, ultra-light weight handles made out of reinforced plastic for sustaining extra load",
            "Best suitable for all types of woods.",
        ],
        application: ["Cut unwanted branches and twigs"],
        precautions: [
            "Fully open jaw-anvil and place/hold job from below and then cut.",
            "Cut branches keeping both hands in symmetry. Do not twist the lopper while cutting",
        ],
        technical: ["Cutting Capacity: 30mm", "Overall length 15”/400mm (approx.)"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 141,
        modelno: "HPS-21",
        URLname: "Foldable-Hand-Pruning-Saw",
        name: "Foldable Hand Pruning Saw",
        img: "HPS-21.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Opens at three angles",
            "Easy press button to open and fold",
        ],
        application: ["Cut / prune branches"],
        technical: [
            "Cutting Capacity: 80mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 7inch",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 142,
        modelno: "HPS-22",
        URLname: "Hand-Pruning-Saw-with-Sheath",
        name: "Hand Pruning Saw with Sheath",
        img: "HPS-22.jpg",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Safety Sheath for carrying and storing",
        ],
        application: ["Cut / prune branches"],
        technical: [
            "Cutting Capacity: 80mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 10”/250mm",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 143,
        modelno: "HPS-23",
        URLname: "Curve-Pruning-Saw ",
        name: "Curve Pruning Saw ",
        img: "HPS-23.webp",
        features: [
            "Blade made out of High Carbon Steel",
            "Triple cut blades on both push & pull strokes",
            "Comfort grip for cutting",
            "Provision for fitting with long telescopic handle for high reach",
            "Safety stroke and extra thrust blade at operating end",
            "Notch blade to shear off hanging ends",
        ],
        application: ["Cut / prune branches "],
        technical: [
            "Cutting Capacity: 100mm ",
            "Pitch 7 teeth/inch",
            "Length of blade: 16”/400mm",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 144,
        modelno: "MCP-31",
        URLname: "Coconut-Pruner",
        name: "Coconut Pruner",
        img: "MCP-31.jpg",
        features: [
            "Blade made out of Carbon Steel",
            "Double heat-treated blade for toughness and retention of sharp edge",
            "Duly sharpened for peeling and harvesting",
            "Light weight heavy duty Aluminium pipe handle with soft grip",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: ["Harvesting Coconut and Banana crops", "Peeling raw coconut"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 145,
        modelno: "MCP-31L",
        URLname: "Coconut-Pruner-Long",
        name: "Coconut Pruner - Long",
        img: "MCP-31L.jpg",
        features: [
            "Blade made out of Carbon Steel",
            "Double heat-treated blade for toughness and retention of sharp edge",
            "Duly sharpened for peeling and harvesting",
            "Light weight heavy duty Aluminium pipe handle with soft grip",
            "Ergonomic handles for fatigue free long duration work",
            "Long handles for high reach",
        ],
        application: ["Harvesting Coconut and Banana crops", "Peeling raw coconut"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 146,
        modelno: "HR-01",
        URLname: "Hand-Rake",
        name: "Hand Rake",
        img: "HR-01.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: [
            "Tough tines for loosening of soil and making beds for plantation",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 147,
        modelno: "TT-02",
        URLname: "Transplant Trowel",
        name: "Transplant-Trowel",
        img: "TT-02.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: [
            "Plant seeds in equal depth and repot without harming the roots",
        ],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        id: 148,
        modelno: "HT-03",
        URLname: "Hand-Trowel",
        name: "Hand Trowel",
        img: "HT-03.jpg",
        features: [
            "Made out of Aluminium alloy",
            "Pressure die-cast for durability",
            "Ergonomic handles for fatigue free long duration work",
        ],
        application: ["For removing soils and repotting plant"],
        URL: "Collase of Garden tools.webp",
        category: "Garden & Agro Tools",
    },
    {
        "id": 1,
        "modelno": "Shakti-2",
        "URLname": "cut-2x-easier-anvil-pruning-shear",
        "name": "Cut 2x Easier Anvil Pruning Shear – 8.1/4”",
        "img": "Shakti-2.jpg",
        "features": ["Ground finish blade made out of high-performance stainless steel", "Double heat-treated blade for extra power", "Laser beam checked cutting edge profile for homogenous sharpness", "Ergonomic cushioned sleeve handles for fatigue free long duration work ", "Phthalate free bright neon green sleeves", "Easy safety locking system", "Surface super coated finish for extra-long life"],
        "application": ["Pruning of both dry and green wood", "An evergreen pruning shear for all to use at home/garden/orchard"],
        "technical": ["Cutting Capacity: 20mm diameter ", "Overall Length: 208mm"],
        "URL": "Collase of Garden tools.webp",
        "category": "Garden & Agro Tools"
    },
    {
        id: 149,
        modelno: "CC-200",
        URLname: "cable-cutter",
        name: "Cable Cutter",
        img: "CC-200.jpg",
        "features": ["Made out of high-grade alloy steel", "Comfortable PVC sleeve", "Easy lock for storing"],
        "application": ["Coaxial cable and wire cutter"],
        "technical": ["Cutting capacity: Φ 10 mm", "Overall length: 170 mm"],
        "URL": "Cable-Cutter.jpg",
        "category": "Cable Cutter"
    },
    {
        id: 150,
        modelno: "CC-200-18",
        URLname: "cable-cutter-1",
        name: "Cable Cutter",
        img: "CC-200-18.jpg",
        "features": ["Made out of high-grade alloy steel", "Ergonomic PVC grips", "Easy lock for storing"],
        "application": ["Cable cutter"],
        "technical": ["Cutting capacity: Φ 18 mm", "Overall length: 200 mm"],
        "URL": "Cable-Cutter.jpg",
        "category": "Cable Cutter"
    },
    {
        id: 151,
        modelno: "CC-300",
        URLname: "micro-cutter-300",
        name: "Micro Cutter",
        img: "CC-300.jpg",
        "features": ["Made out of high-grade alloy steel", "Ergonomic PVC handle grips"],
        "application": ["For cutting Copper/Aluminium conductor", "Easy cutting with one hand operation due to high transmission ratio"],
        "technical": ["Cutting capacity: up-to 24mm²", "Overall length: 300 mm"],
        "URL": "Cable-Cutter.webp",
        "category": "Cable Cutter"
    },
    {
        id: 153,
        modelno: "MT-06",
        URLname: "micro-shear",
        name: "Micro Shear",
        img: "MT-06.jpg",
        "features": ["Made out of alloy steel", "Ergonomic PVC handle grips"],
        "application": ["Suitable for cutting wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.4 mm", "Overall length: 125 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 154,
        modelno: "MT-07",
        URLname: "nipper-cutter",
        name: "Nipper Cutter",
        img: "MT-07.jpg",
        "features": ["Made out of alloy steel", "Dual tone 100% lead free polystyrene handle grips"],
        "application": ["A versatile tool for cutting Copper and Aluminium conductors"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.2 mm", "Overall length 120 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 155,
        modelno: "MT-010",
        URLname: "micro-cutter",
        name: "Micro Cutter",
        img: "MT-010.jpg",
        "features": ["Made out of alloy steel", "Ergonomic PVC handle grips"],
        "application": ["Suitable to cut wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to 1.2 mm", "Overall length: 125 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 156,
        modelno: "MT-011",
        URLname: "palm-nipper",
        name: "Palm Nipper",
        img: "MT-011.jpg",
        "features": ["Made out of high-grade alloy steel", "Ergonomic PVC handle grips", "Cutting edges duly ground and induction hardened"],
        "application": ["Suitable for cutting soft Copper and Aluminium wires"],
        "technical": ["Cutting capacity: Up-to Φ1.5 mm", "Overall length: 112 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 157,
        modelno: "MT-012",
        URLname: "heavy-duty-diagonal-nipper",
        name: "Heavy Duty Diagonal Nipper",
        img: "MT-012.jpg",
        "features": ["Made out of high carbon alloy steel", "Ergonomic PVC cushioned grips", "Cutting edges induction hardened for long life"],
        "application": ["Suitable for cutting Copper & Aluminium conductors", "Widely used in Electronics, Telecommunication, Computers & PCB production"],
        "technical": ["Stripping capacity: Up-to Φ 2.0 mm", "Overall length: 150 mm"],
        "URL": "micro shears banner.webp",
        "category": "NIPPERS & MICRO SHEARS"
    },
    {
        id: 158,
        modelno: "MT-535",
        URLname: "long-nose-plier",
        name: "Long Nose Plier",
        img: "MT-535.webp",
        "features": ["Forged out of high-grade Chrome-Vanadium steel", "Jaws duly induction hardened", "Fine serrations diagonally opposite enabling gripping of thinnest wires and components", "Injection moulded cellulose acetate sleeves"],
        "application": ["For cutting and holding fine electrical wires", "For electricians and workmen"],
        "technical": ["Overall length: 7”/180 mm"],
        "URL": "Pliers-and-Nose-Pliers.webp",
        "category": "Pliers and Nose Pliers"
    },

    {
        id: 159,
        modelno: "MT-555",
        URLname: "combination-plier",
        name: "Combination Plier",
        "img": "MT-555.webp",
        "features": ["Forged out of high-grade Chrome-Vanadium steel", "Jaws duly induction hardened", "Injection moulded heavy duty CA-FR anti-slip and high hand grip sleeves", "Plier insulated sleeves are tested for 2500 AC Volts"],
        "application": ["For bending and cutting through metal wire and electrical cables", "For electricians and workmen"],
        "technical": ["Overall length: 8”/200 mm"],
        "URL": "Pliers-and-Nose-Pliers.webp",
        "category": "Pliers and Nose Pliers"
    },
    {
        id: 160,
        modelno: "MT-02Dx",
        URLname: "Wire-Stripper",
        name: "Automatic Wire Stripper & Cutter",
        img: "MT-02DX.jpg",
        "features": ["Made out of Nylon body", "Smooth operation free from any hindrance"],
        "application": ["A unique tool to strip & cut insulation of copper wire ranging from 0.5mm to 2.5mm", "Suitable for electronics, aviation, automobile and day to day maintenance"],
        "technical": ["Cutting/stripping capacity: Copper wire Φ 14-24 AWG / 0.5 mm² - 6.0 mm²", "Overall length: 180 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 166,
        modelno: "MT-02Dx Plus",
        URLname: "Wire Stripper-1",
        name: "Automatic Wire Stripper & Cutter",
        img: "MT-02Dx-PLUS.jpg",
        "features": ["New improved engineering re-enforced plastic material body", "Smooth and fast operation, free from any hindrance"],
        "application": ["Multitec 2-in-1 wire stripper and cutter strips wires and cables accurately and cleanly on both solid core and stranded wire.", "Suitable for electronics, aviation, automobile and day to day maintenance", "Suitable for stripping cable length capacity up-to 20 mm"],
        "technical": ["Cutting/stripping capacity: Copper wire Φ 14-24 AWG / 0.5 mm² - 6.0 mm²", "Overall length: 180 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 167,
        modelno: "MT-150B",
        URLname: "Wire-Stripper-&-Cutter",
        name: " Wire Stripper & Cutter",
        img: "MT-150B.jpg",
        "features": ["Made out of high-grade tempered steel", "Ergonomic PVC cushioned grips", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 161,
        modelno: "MT-150C-Champion",
        URLname: "Wire-Stripper-&-Cutter-Champion",
        name: "Wire Stripper & Cutter (Champion)",
        img: "MT-150C-Champion.jpg",
        "features": ["Made out of high-grade tempered steel", "Heavy-duty construction with precision cutting edges", "Handles made of strong engineering plastic with extra hand grip", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 162,
        modelno: "MT-68C",
        URLname: "Wire-Stripper-&-Cutter-68C",
        name: "Wire Stripper & Cutter",
        img: "MT-68C.jpg",
        "features": ["Made out of high-grade tempered steel", "Ergonomic PVC cushioned grips", "With screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 163,
        modelno: "MT-68D",
        URLname: "Wire-Stripper-&-Cutter-1",
        name: "Wire Stripper & Cutter",
        img: "MT-68D.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly ground mirror finished blades", "Ergonomic PVC cushioned grips", "With spring and screw gauge adjuster"],
        "application": ["Suitable to cut and strip electrical wires"],
        "technical": ["Stripping capacity: Copper wire up-to Φ 4.0 mm²", "Overall length: 140 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 164,
        modelno: "MT-101",
        URLname: "Medium-Duty-Wire-Stripper-&-Cutter",
        name: "Medium Duty Wire Stripper & Cutter",
        img: "MT-101.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly ground mirror finished blades", "Ergonomic heavy duty PVC cushioned grips", "Has long wire cutter blades", "Cutting edges duly polished"],
        "application": ["Suitable to cut and strip Copper and Aluminium wires"],
        "technical": ["Stripping capacity: Copper and Aluminium wire up-to Φ 3.0 mm²", "Cutting capacity: ϕ 3.0 mm", "Overall length: 127 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 165,
        modelno: "MT-501",
        URLname: "Heavy-Duty-Wire-Stripper-&-Cutter",
        name: "Heavy Duty Wire Stripper & Cutter",
        img: "MT-501.jpg",
        "features": ["Made out of high-grade tempered steel", "Duly induction hardened", "Ergonomic heavy duty PVC cushioned grips", "With gauge adjuster"],
        "application": ["Suitable to cut and strip heavy duty electrical wires and cables"],
        "technical": ["Stripping capacity: 2.1 SWG to 6.0 SWG", "Cutting capacity: ϕ 3.0 mm", "Overall length: 170 mm"],
        "URL": "WIRE-STRIPPER.webp",
        "category": "Wire Stripper"
    },
    {
        id: 178,
        modelno: "MT-150B SS",
        URLname: "Stainless-Steel-Wire-Stripper-&-Cutter",
        name: "Stainless-Steel Wire Stripper & Cutter",
        img: "MT-150B-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Heavy duty handles with finger holding ring", "Ergonomic TPE bright grips", "Rust proof tool", "Satin finish"],
        "application": ["A versatile tool for cutting and stripping Copper and Aluminium wires"],
        "technical": ["Cutting/stripping capacity: Φ 2.0 mm", "Overall length: 140 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 176,
        modelno: "MT-68C SS",
        URLname: "Stainless-Steel-Wire-Stripper-&-Cutter-2",
        name: "Stainless-Steel Wire Stripper & Cutter",
        img: "MT-68C-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Heavy duty handles with finger holding ring", "Ergonomic TPE bright grips", "Rust proof tool", "Satin finish", "Handle contour for small palm"],
        "application": ["A versatile tool for cutting and stripping Copper and Aluminium wires"],
        "technical": ["Cutting/stripping capacity: Φ 2.0 mm", "Overall length: 140 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 179,
        modelno: "MT-160B SS",
        URLname: "Stainless-Steel-Wire-Stripper-&-Cutter-1",
        name: "Stainless-Steel Wire Stripper & Cutter",
        img: "MT-160B-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Heavy duty handles", "Strong high performance engineering plastic handle grips", "Rust proof tool", "Easy locking mechanism", "Satin finish"],
        "application": ["Specially designed for cutting & stripping electrical wires"],
        "technical": ["Cutting/stripping capacity: up-to Φ 12.0 mm or 10 mm²", "Overall length: 150 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 169,
        modelno: "MT-07 SS",
        URLname: "Stainless-Steel-Nipper",
        name: "Stainless-Steel Nipper",
        img: "MT-07-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Dual tone 100% lead free polystyrene grips", "Improved bigger handles for high handle grip", "Rust proof tool", "Satin finish"],
        "application": ["A versatile tool for cutting Copper wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to ϕ 1.2 mm", "Overall length: 115 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 170,
        modelno: "MT-010 SS",
        URLname: "Stainless-Steel-Micro-Cutter",
        name: "Stainless-Steel Micro Cutter",
        img: "MT-010-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic TPE grips", "Rust proof tool", "Satin finish"],
        "application": ["A versatile tool for cutting Copper wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to ϕ 1.2 mm", "Overall length 125 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 168,
        modelno: "MT-06 SS",
        URLname: "Stainless-Steel-Micro-Shear",
        name: " Stainless-Steel Micro Shear",
        img: "MT-06-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic TPE grips", "Rust proof tool", "Satin finish"],
        "application": ["A versatile tool for cutting Copper wires"],
        "technical": ["Cutting capacity:  Φ 0.8 mm to ϕ 1.4 mm", "Overall length: 128 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 172,
        modelno: "MT-012 SS",
        URLname: "Stainless-Steel-Heavy-Duty-Diagonal-Nipper",
        name: "Stainless-Steel Heavy-Duty Diagonal Nipper",
        img: "MT-012-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic grips made of non-slip rubber", "Rust proof tool", "Satin finish"],
        "application": ["A versatile tool for cutting Copper wires"],
        "technical": ["Cutting capacity: Φ 0.8 mm to ϕ 1.4 mm", "Overall length: 150 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 173,
        modelno: "MT-013 SS",
        URLname: "Stainless-Steel-Heavy-Duty-Flash-Cutter",
        name: "Stainless-Steel Heavy-Duty Flash Cutter",
        img: "MT-013-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Heavy duty construction", "Ergonomic non-slip grips", "Rust proof tool", "Satin finish", "Smooth movement for long and continuous use"],
        "application": ["A versatile tool for plastic moulding industries", "For cutting plastic runners/ gates/ burrs etc."],
        "technical": ["Overall length: 165 mm"],
        "caution": ["Not suitable for cutting wires / cables"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 174,
        modelno: "MT-015 SS",
        URLname: "Stainless-Steel-Mini-Nipper",
        name: "Stainless Steel Mini Nipper",
        img: "MT-015-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic non-slip grips", "Razor sharp cutting edges duly ground and polished", "Rust proof tool"],
        "application": ["Designed to cut soft Copper & Aluminium wires"],
        "technical": ["Cutting capacity: Up-to Φ 1.3 mm", "Overall length: 115 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 171,
        modelno: "MT-011 HD",
        URLname: "Stainless-Steel-Mini-Nipper-1",
        name: "Stainless-Steel Mini Nipper",
        img: "MT-011-HD.jpg",
        "features": ["Made out of high-grade stainless steel", "Long life screw joint (scrivets)", "Attractive ergonomic grips", "Safety lock", "Rust proof tool"],
        "application": ["Designed to cut soft Copper & Aluminium wires", "Most useful for Jewelry industry"],
        "technical": ["Cutting capacity: Up-to Φ 2.0 mm", "Overall length: 115 mm"],
        "caution": ["Not suitable for cutting steel wires"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 175,
        modelno: "MT-016 SS",
        URLname: "Stainless-Steel-Precision-Shear-Scissor",
        name: "Stainless-Steel Precision Shear/Scissor",
        img: "MT-016-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Inox, rust proof stainless steel", "Very sharp grounded cutting edges with bevel edges", "Rust resistant steel rivets", "Attractive ergonomic grips", "Smooth opening spring and safety lock"],
        "application": ["For cutting Copper/Alluminium/Sterling Silver wires", "For cutting Gold/Silver/Alluminium foils", "Most useful for Jewelry industry"],
        "technical": ["Cutting capacity: Up-to Φ 1.0 mm foils", "Overall length: 115 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 177,
        modelno: "MT-111 SS",
        URLname: "Stainless-Steel-Palm-Nipper",
        name: "Stainless-Steel Palm Nipper",
        img: "MT-0111-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic non-slip soft grips", "Sharp grounded cutting edges", "Rust proof stainless steel"],
        "application": ["Suitable for cutting soft Copper/Alluminium wires", "For cutting Gold/Silver/Alluminium foils", "Best tool for Jewelry industry"],
        "technical": ["Cutting capacity: Up-to Φ 1.5 mm", "Overall length: 112 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 166,
        modelno: "CC-100 SS",
        URLname: "Stainless-Steel-Cable-Cutter",
        name: "Stainless-Steel Cable Cutter",
        img: "CC-100-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic non-slip grips", "Sharp grounded cutting edges", "Safety lock", "Rust proof stainless steel"],
        "application": ["Suitable for cutting cables"],
        "technical": ["Cutting capacity: Up-to Φ 10.0 mm", "Overall length: 100 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 167,
        modelno: "CC-200 SS",
        URLname: "Stainless-Steel-Cable-Cutter-1",
        name: "Stainless-Steel Cable Cutter",
        img: "CC-200-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic non-slip grips", "Sharp grounded cutting edges", "Safety lock", "Rust proof stainless steel"],
        "application": ["Suitable for cutting cables"],
        "technical": ["Cutting capacity: Up-to Φ 18.0 mm", "Overall length: 200 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 180,
        modelno: "MT-901 SS",
        URLname: "Stainless-Steel-Multipurpose-Shear",
        name: "Stainless-Steel Multipurpose Shear",
        img: "MT-901-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic contoured grips", "Sharp grounded serrated cutting edges", "Safety lock", "Rust proof stainless steel"],
        "application": ["Suitable for cutting/shearing wide variety of material – electrical wires, cord, thin tree branches, nylon ropes, card board etc.", "Suitable harvesting fruits and vegetables without damaging the plant."],
        "technical": ["Overall length: 190 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        id: 181,
        modelno: "MT-902 SS",
        URLname: "Stainless-Steel-Multipurpose-Shear-Bent-Jaws",
        name: "Stainless-Steel Multipurpose Shear – Bent Jaws",
        img: "MT-902-SS.jpg",
        "features": ["Made out of high-grade stainless steel", "Ergonomic contoured grips", "Bent cutting jaws", "Sharp grounded serrated cutting edges", "Safety lock", "Rust proof stainless steel"],
        "application": ["Suitable for cutting/shearing wide variety of material – electrical wires, cord, thin tree branches, nylon ropes, card board etc.", "Suitable harvesting fruits and vegetables without damaging the plant.", "Easy to reach out to difficult position"],
        "technical": ["Overall length: 190 mm"],
        "URL": "STAINLESS-STEEL-TOOLS.webp",
        "category": "Stainless Steel Tools"
    },
    {
        "id": 182,
        "modelno": "HSLK-100",
        "URLname": "Hex-Short-Series-Standard-Allen-Keys-without-Ball-Ended",
        "name": "Hex Short Series Standard Allen Keys without Ball Ended",
        "nameSpace": "(9 Pcs. / 6 Pcs. Set & Loose Allen Key)",
        "img": "HSLK-100.jpg",
        "features": ["Made out of CVM alloy steel", "Brown Coated for surface protection", "Unique jacket to hold hex keys in respective size slots"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered to 58-60 HRc", "Sustain high torsional strength", "Sets (9Pcs. Set and 6 Pcs. Set) and loose available in MM (1.5 to 10mm) and SAE (1/16” to 3/8”)"],
        "dataTable": {
            "columns": [
                {"key": "model_no_mm", "label": "Model No. MM"},
                {"key": "size_mm", "label": "Sizes MM"},
                {"key": "model_no_inches", "label": "Model No. Inches"},
                {"key": "size_inches", "label": "Sizes Inches"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {
                    "model_no_mm": "SS 1.5",
                    "size_mm": "1.5",
                    "model_no_inches": "SS 116",
                    "size_inches": "1/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.0",
                    "size_mm": "2.0",
                    "model_no_inches": "SS 564",
                    "size_inches": "5/64”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.5",
                    "size_mm": "2.5",
                    "model_no_inches": "SS 332",
                    "size_inches": "3/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 3.0",
                    "size_mm": "3.0",
                    "model_no_inches": "SS 18",
                    "size_inches": "1/8”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 4.0",
                    "size_mm": "4.0",
                    "model_no_inches": "SS 532",
                    "size_inches": "5/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 5.0",
                    "size_mm": "5.0",
                    "model_no_inches": "SS 316",
                    "size_inches": "3/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 6.0",
                    "size_mm": "6.0",
                    "model_no_inches": "SS 732",
                    "size_inches": "7/32”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 8.0",
                    "size_mm": "8.0",
                    "model_no_inches": "SS 516",
                    "size_inches": "5/16”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 10.0",
                    "size_mm": "10.0",
                    "model_no_inches": "SS 38",
                    "size_inches": "3/8”",
                    "qty_per_box": "25"
                },
                {
                    "model_no_mm": "HSLK-100M (9)",
                    "size_mm": "9 PCS. SET (1.5-10MM)",
                    "model_no_inches": "HSLK-100i (9)",
                    "size_inches": "9 PCS. SET (1/16”-3/8”)",
                    "qty_per_box": "10 SETS"
                },
                {
                    "model_no_mm": "HSLK-100M (6)",
                    "size_mm": "6 PCS. SET (1.5-5 MM)",
                    "model_no_inches": "HSLK-100i (6)",
                    "size_inches": "6 PCS. SET (1/16”-3/16”)",
                    "qty_per_box": "10 SETS"
                }
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 183,
        "modelno": "HBLK-100",
        "URLname": "Long-Series-Ball-Ended-Allen-Keys",
        "name": "Long Series Ball Ended Allen Keys",
        "nameSpace": "(9 Pcs. / 6 Pcs. Set & Loose Allen Key)",
        "img": "HBLK-100.jpg",
        "features": ["Made out of CVM alloy steel", "Ball-end to ease use in restricted area at an angle of 30°", "Brown Coated for surface protection", "Unique jacket to hold hex keys in respective size slots"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered to 58-60 HRc", "Sustain high torsional strength", "Sets (9Pcs. Set and 6 Pcs. Set) and loose available in MM (1.5 to 10mm) and SAE (1/16” to 3/8”)"],
        "dataTable": {
            "columns": [
                {"key": "model_no_mm", "label": "Model No. MM"},
                {"key": "size_mm", "label": "Sizes MM"},
                {"key": "model_no_inches", "label": "Model No. Inches"},
                {"key": "size_inches", "label": "Sizes Inches"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {
                    "model_no_mm": "SS 1.5",
                    "size_mm": "1.5",
                    "model_no_inches": "SS 116",
                    "size_inches": "1/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.0",
                    "size_mm": "2.0",
                    "model_no_inches": "SS 564",
                    "size_inches": "5/64”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.5",
                    "size_mm": "2.5",
                    "model_no_inches": "SS 332",
                    "size_inches": "3/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 3.0",
                    "size_mm": "3.0",
                    "model_no_inches": "SS 18",
                    "size_inches": "1/8”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 4.0",
                    "size_mm": "4.0",
                    "model_no_inches": "SS 532",
                    "size_inches": "5/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 5.0",
                    "size_mm": "5.0",
                    "model_no_inches": "SS 316",
                    "size_inches": "3/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 6.0",
                    "size_mm": "6.0",
                    "model_no_inches": "SS 732",
                    "size_inches": "7/32”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 8.0",
                    "size_mm": "8.0",
                    "model_no_inches": "SS 516",
                    "size_inches": "5/16”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 10.0",
                    "size_mm": "10.0",
                    "model_no_inches": "SS 38",
                    "size_inches": "3/8”",
                    "qty_per_box": "25"
                },
                {
                    "model_no_mm": "HSLK-100M (9)",
                    "size_mm": "9 PCS. SET (1.5-10MM)",
                    "model_no_inches": "HSLK-100i (9)",
                    "size_inches": "9 PCS. SET (1/16”-3/8”)",
                    "qty_per_box": "10 SETS"
                },
                {
                    "model_no_mm": "HSLK-100M (6)",
                    "size_mm": "6 PCS. SET (1.5-5 MM)",
                    "model_no_inches": "HSLK-100i (6)",
                    "size_inches": "6 PCS. SET (1/16”-3/16”)",
                    "qty_per_box": "10 SETS"
                }
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 184,
        "modelno": "HBLK-200",
        "URLname": "Extra-Long-Series-Ball-Ended-Allen-Keys",
        "name": " Extra Long Series Ball Ended Allen Keys",
        "nameSpace": "(9 Pcs. / 6 Pcs. Set & Loose Allen Key)",
        "img": "HBLK-200.jpg",
        "features": ["Made out of CVM alloy steel", "Ball-end to ease use in restricted area at an angle of 30°", "Brown Coated for surface protection", "Unique jacket to hold hex keys in respective size slots"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered to 58-60 HRc", "Sustain high torsional strength", "Sets (9Pcs. Set and 6 Pcs. Set) and loose available in MM (1.5 to 10mm) and SAE (1/16” to 3/8”)"],
        "dataTable": {
            "columns": [
                {"key": "model_no_mm", "label": "Model No. MM"},
                {"key": "size_mm", "label": "Sizes MM"},
                {"key": "model_no_inches", "label": "Model No. Inches"},
                {"key": "size_inches", "label": "Sizes Inches"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {
                    "model_no_mm": "SS 1.5",
                    "size_mm": "1.5",
                    "model_no_inches": "SS 116",
                    "size_inches": "1/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.0",
                    "size_mm": "2.0",
                    "model_no_inches": "SS 564",
                    "size_inches": "5/64”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 2.5",
                    "size_mm": "2.5",
                    "model_no_inches": "SS 332",
                    "size_inches": "3/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 3.0",
                    "size_mm": "3.0",
                    "model_no_inches": "SS 18",
                    "size_inches": "1/8”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 4.0",
                    "size_mm": "4.0",
                    "model_no_inches": "SS 532",
                    "size_inches": "5/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 5.0",
                    "size_mm": "5.0",
                    "model_no_inches": "SS 316",
                    "size_inches": "3/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "SS 6.0",
                    "size_mm": "6.0",
                    "model_no_inches": "SS 732",
                    "size_inches": "7/32”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 8.0",
                    "size_mm": "8.0",
                    "model_no_inches": "SS 516",
                    "size_inches": "5/16”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "SS 10.0",
                    "size_mm": "10.0",
                    "model_no_inches": "SS 38",
                    "size_inches": "3/8”",
                    "qty_per_box": "25"
                },
                {
                    "model_no_mm": "HSLK-100M (9)",
                    "size_mm": "9 PCS. SET (1.5-10MM)",
                    "model_no_inches": "HSLK-100i (9)",
                    "size_inches": "9 PCS. SET (1/16”-3/8”)",
                    "qty_per_box": "10 SETS"
                },
                {
                    "model_no_mm": "HSLK-100M (6)",
                    "size_mm": "6 PCS. SET (1.5-5 MM)",
                    "model_no_inches": "HSLK-100i (6)",
                    "size_inches": "6 PCS. SET (1/16”-3/16”)",
                    "qty_per_box": "10 SETS"
                }
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 185,
        "modelno": "THK-100",
        "URLname": "Tee-Handle-Allen-Keys",
        "name": "Tee Handle Allen Keys",
        "nameSpace": "(9 Pcs. Tee-Handle Allen/Hex Driver Set (Metric/Inches) & Loose)",
        "img": "THK-100.jpg",
        "features": ["Made out of high-grade alloy steel", "Ends duly faced and chamfered for easy & perfect fitment", "Brown Coated for surface protection", "T-Handles are made of high toughness engineering plastic", "Hex key rack for storage and easy access", "Includes 1 pc. 2-in-1 screw driver Model No. R-6100 (PH2-S6)", "Magnetic tip for better leverage", "Long length for easy access to restricted places"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered", "Sustain high torsional strength", "Sets (9Pcs. Set and 6 Pcs. Set) and loose available in MM (1.5 to 10mm) and SAE (1/16” to 3/8”)"],
        "dataTable": {
            "columns": [
                {"key": "model_no_mm", "label": "Model No. MM"},
                {"key": "size_mm", "label": "Sizes MM"},
                {"key": "model_no_inches", "label": "Model No. Inches"},
                {"key": "size_inches", "label": "Sizes Inches"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {
                    "model_no_mm": "THK 1.5",
                    "size_mm": "1.5",
                    "model_no_inches": "THK 116",
                    "size_inches": "1/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 2.0",
                    "size_mm": "2.0",
                    "model_no_inches": "THK 564",
                    "size_inches": "5/64”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 2.5",
                    "size_mm": "2.5",
                    "model_no_inches": "THK 332",
                    "size_inches": "3/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 3.0",
                    "size_mm": "3.0",
                    "model_no_inches": "THK 18",
                    "size_inches": "1/8”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 4.0",
                    "size_mm": "4.0",
                    "model_no_inches": "THK 532",
                    "size_inches": "5/32”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 5.0",
                    "size_mm": "5.0",
                    "model_no_inches": "THK 316",
                    "size_inches": "3/16”",
                    "qty_per_box": "100"
                },
                {
                    "model_no_mm": "THK 6.0",
                    "size_mm": "6.0",
                    "model_no_inches": "THK 732",
                    "size_inches": "7/32”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "THK 8.0",
                    "size_mm": "8.0",
                    "model_no_inches": "THK 516",
                    "size_inches": "5/16”",
                    "qty_per_box": "50"
                },
                {
                    "model_no_mm": "THK 10.0",
                    "size_mm": "10.0",
                    "model_no_inches": "THK 38",
                    "size_inches": "3/8”",
                    "qty_per_box": "25"
                },
                {
                    "model_no_mm": "THK-100M (9)",
                    "size_mm": "9 PCS. SET (1.5-10MM)",
                    "model_no_inches": "THK-100i (9)",
                    "size_inches": "9 PCS. SET (1/16”-3/8”)",
                    "qty_per_box": "12 SETS"
                },
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 186,
        "modelno": "THK-200 Series (Loose Size Only)",
        "URLname": "Extra-Long-Length-Tee-Handle-Allen-Hex-Keys-Metric-Inches",
        "name": "Extra Long Length Tee Handle Allen/Hex Keys (Metric/Inches)",
        "img": "THK-200.jpg",
        "features": ["Made out of CVM alloy steel", "Ends duly faced and chamfered for easy & perfect fitment", "Brown Coated for surface protection", "Wide cellulose acetate handles for better leverage", "Long length for reaching difficult places"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered", "Sustain high torsional strength"],
        "dataTable": {
            "columns": [
                {"key": "metric_mm_model_no", "label": "METRIC (MM) Model No."},
                {"key": "model_no_inches", "label": "Model No. Inches"},
                {"key": "a", "label": "(A)"},
                {"key": "b", "label": "(B)"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {
                    "metric_mm_model_no": "THK-200-2.5",
                    "model_no_inches": "THK-200-3/32”",
                    "a": "200MM",
                    "b": "80MM",
                    "qty_per_box": "50"
                },
                {
                    "metric_mm_model_no": "THK-200-3.0",
                    "model_no_inches": "THK-200-1/8”",
                    "a": "200MM",
                    "b": "80MM",
                    "qty_per_box": "50"
                },
                {
                    "metric_mm_model_no": "THK-200-4.0",
                    "model_no_inches": "THK-200-5/32”",
                    "a": "200MM",
                    "b": "100MM",
                    "qty_per_box": "25"
                },
                {
                    "metric_mm_model_no": "THK-200-5.0",
                    "model_no_inches": "THK-200-3/16”",
                    "a": "200MM",
                    "b": "150MM",
                    "qty_per_box": "25"
                },
                {
                    "metric_mm_model_no": "THK-200-6.0",
                    "model_no_inches": "THK-200-7/32”",
                    "a": "200MM",
                    "b": "150MM",
                    "qty_per_box": "25"
                },
                {
                    "metric_mm_model_no": "THK-200-8.0",
                    "model_no_inches": "THK-200-5/16”",
                    "a": "350MM",
                    "b": "150MM",
                    "qty_per_box": "25"
                },
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 187,
        "modelno": "THK-300 Series (Loose Size Only)",
        "URLname": "Extra-Length-Tee-Handle-Allen-Hex-Driver",
        "name": "Extra Length Tee Handle Allen/Hex Driver",
        "img": "THK-300-Series.jpg",
        "features": ["Made out of high-grade alloy steel", "Ends duly faced and chamfered for easy & perfect fitment", "Coated for long life", "PVC sleeves for comfort operation", "Long length for reaching difficult places"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "a", "label": "(A)"},
                {"key": "b", "label": "(B)"},
                {"key": "c", "label": "(C)"},
                {"key": "qty_per_box", "label": "Qty Per Box"}
            ],
            "rows": [
                {"model_no": "THK-300-3.0MM", "a": "300MM", "b": "ϕ 6.0MM", "c": "3.0MM HEX ", "qty_per_box": "50"},
                {"model_no": "THK-300-4.0MM", "a": "300MM", "b": "ϕ 8.0MM", "c": "4.0MM HEX ", "qty_per_box": "50"},
                {"model_no": "THK-300-5.0MM", "a": "300MM", "b": "ϕ 10.0MM", "c": "5.0MM HEX ", "qty_per_box": "50"},
                {"model_no": "THK-300-6.0MM", "a": "300MM", "b": "ϕ 12.0MM", "c": "6.0MM HEX ", "qty_per_box": "50"},
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 188,
        "modelno": "HD/BHD Series",
        "URLname": "Screw-Driver-Style-Allen-Ball-Allen-Drivers",
        "name": "Screw Driver Style Allen Ball Allen Drivers",
        "img": "HD-BHD-Series.jpg",
        "features": ["Made out of high-grade alloy steel", "Ball-end or Plain-end", "Coated for long life", "Ergonomic handles", "Long length for reaching difficult places"],
        "application": ["For operating Allen Bolts"],
        "technical": ["Hardened and tempered"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "plain_ball_Hex", "label": "Plain/Ball Hex"},
                {"key": "hex_size", "label": "Hex Size "},
                {"key": "projected-length", "label": "Projected Length"},
            ],
            "rows": [
                {"model_no": "HD7515", "plain_ball_Hex": "Plain End", "hex_size": "1.5MM", "projected-length": "75MM"},
                {"model_no": "BHD7515", "plain_ball_Hex": "Ball End ", "hex_size": "1.5MM", "projected-length": "75MM"},
                {"model_no": "HD752", "plain_ball_Hex": "Plain End", "hex_size": "2.0MM", "projected-length": "75MM"},
                {"model_no": "BHD752", "plain_ball_Hex": "Ball End ", "hex_size": "2.0MM", "projected-length": "75MM"},
                {"model_no": "HD7525", "plain_ball_Hex": "Plain End", "hex_size": "2.5MM ", "projected-length": "75MM"},
                {
                    "model_no": "BHD7525",
                    "plain_ball_Hex": "Ball End ",
                    "hex_size": "2.5MM ",
                    "projected-length": "75MM"
                },
                {
                    "model_no": "HD1503 ",
                    "plain_ball_Hex": "Plain End",
                    "hex_size": "3.0MM",
                    "projected-length": "150MM"
                },
                {
                    "model_no": "BHD1503",
                    "plain_ball_Hex": "Ball End ",
                    "hex_size": "3.0MM",
                    "projected-length": "150MM"
                },
                {
                    "model_no": "HD1504 ",
                    "plain_ball_Hex": "Plain End",
                    "hex_size": "4.0MM",
                    "projected-length": "150MM"
                },
                {
                    "model_no": "BHD1504",
                    "plain_ball_Hex": "Ball End ",
                    "hex_size": "4.0MM",
                    "projected-length": "150MM"
                },
                {"model_no": "HD1505", "plain_ball_Hex": "Plain End", "hex_size": "5.0MM", "projected-length": "150MM"},
                {
                    "model_no": "BHD1505",
                    "plain_ball_Hex": "Ball End ",
                    "hex_size": "5.0MM",
                    "projected-length": "150MM"
                },
                {"model_no": "BHD315", "plain_ball_Hex": "Ball End ", "hex_size": "1.5MM", "projected-length": "300MM"},
                {"model_no": "BHD302", "plain_ball_Hex": "Ball End ", "hex_size": "2.0MM", "projected-length": "300MM"},
                {"model_no": "BHD325", "plain_ball_Hex": "Ball End ", "hex_size": "2.5MM", "projected-length": "300MM"},
                {"model_no": "BHD303", "plain_ball_Hex": "Ball End ", "hex_size": "3.0MM", "projected-length": "300MM"},
                {"model_no": "BHD304", "plain_ball_Hex": "Ball End ", "hex_size": "4.0MM", "projected-length": "300MM"},
                {"model_no": "BHD305", "plain_ball_Hex": "Ball End ", "hex_size": "5.0MM", "projected-length": "300MM"},
                {"model_no": "BHD306", "plain_ball_Hex": "Ball End ", "hex_size": "6.0MM", "projected-length": "300MM"},
            ]
        },
        "URL": "HEX-KEYS.webp",
        "category": "Hex keys"
    },
    {
        "id": 196,
        "modelno": "",
        "URLname": "insulated-un-insulated-2-in-1-reversible-screw-driver-with-hexagon-rod-and-extra-hard-tips-6mm-hex",
        "name": "Insulated / Un-Insulated 2-in-1 Reversible Screw Driver with Hexagon Rod & Extra Hard Tips (6mm Hex) ",
        "img": "R-6100.jpg",
        "features": ["Shank made out of High-grade alloy steel", "2-in-1 screw driver ", "Tip coated for long life", "Hexagon shaft screw driver shank for turning with a spanner for extra torque to open extra tight screws", "Available in projected lengths of 100mm, 150mm, 200mm, 250mm & 300mm"],
        "application": ["For loosening and tightening Phillip and Slotted head screws. "],
        "technical": ["Hardened and tempered ", "Head PH-2 and S-6 in reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "R 6100 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "R 6100i ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "R 6150",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "R 6150i",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "R 6200",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "200 mm"
                },
                {
                    "model_no": "R 6200i",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "200 mm"
                },
                {
                    "model_no": "R 6250",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
                {
                    "model_no": "R 6250i",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
                {
                    "model_no": "R 6300 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "300 mm"
                },
            ]
        },
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 197,
        "modelno": "",
        "URLname": "insulated-un-insulated-2-in-1-reversible-t-handle-screw-driver-with-hexagon-rod-and-extra-hard-tips",
        "name": "Insulated / Un-Insulated 2-in-1 Reversible T-Handle Screw Driver with Hexagon Rod & Extra Hard Tips",
        "img": "TR-6150.jpg",
        "features": ["Shank made out of High-grade alloy steel ", "2-in-1 screw driver – PH-2 & S-6 ", "Tip coated for long life", "T-Handle made of transparent high-grade tough and durable plastic", "T-Driver configuration allows application of high torque on screw head", "Available in projected lengths of 100mm, 150mm, 200mm, 250mm & 300mm", "Available in various head configuration – Phillip, Minus (flat), Torx, Allen, Ball Allen"],
        "application": ["For loosening and tightening Phillip and Slotted head screws.", "Ideal for tightening self-tapping screws in wood/metal "],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome-plated finish and also colour coded three tone finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "TR 6150 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "TR 6150i ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "TR 6250 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
                {
                    "model_no": "TR 6250i",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
            ]
        },
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 198,
        "modelno": "TRB-Series",
        "URLname": "4-in-1-tee-driver-reversible-screw-driver-with-hexagon-rod-and-extra-hard-tips",
        "name": " 4-in-1 (Tee-Driver) Reversible Screw Driver with Hexagon Rod and Extra Hard Tips",
        "img": "TRB-6150.jpeg",
        "features": ["Shank made out of high-grade alloy steel ", "Unique design 4-in-1 screw driver in Tee type orientation with two ends PH-2 and S-6 ", "Tip coated for long life", "Hexagonal shaft screw driver shank for turning with a spanner, to open extra tight screws "],
        "application": ["For loosening and tightening Phillip and Slotted head screws", "Ideal for use in high torque applications available in all standard projected lengths "],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome-plated finish "],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "TRB 6150 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "TRB 6250 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
            ]
        },
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 199,
        "modelno": "TST-Series",
        "URLname": "2-in-1-reversible-screw-driver-with-neon-bulb-tester-and-electrical-insulated-rods-6mm-hex",
        "name": "2-in-1 Reversible Screw Driver with Neon Bulb Tester and Electrical Insulated Rods (6mm Hex)",
        "img": "THK-100.jpeg",
        "features": ["Shank made out of high-grade alloy steel ", "Handle made out of Angel-blue colour CA", "Screw driver handle with neon bulb tester for voltage detection up-to 500V", "Tip coated for long life", "Electrically insulated for safety against electric shocks "],
        "application": ["For loosening and tightening Phillip and Slotted head screws", "Voltage detection"],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome-plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "TST-6100(i)",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "TST-6150(i)",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "TST-6250(i)",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
            ]
        },
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 201,
        "modelno": "DG- Series",
        "URLname": "double-grip-anti-slip-&-cushioned-2-in-1-reversible-screw-driver-with-hexagon-rod-and-extra-hard-tips-6mm-hex",
        "name": "Double Grip Anti-slip & Cushioned 2-in-1 Reversible Screw Driver with Hexagon Rod & Extra Hard Tips (6mm Hex) ",
        "img": "DG-Series.jpg",
        "features": ["First time in India", "Shank made out of high-grade alloy steel ", "Double-Grip series 2-in-1 screw driver handle is made of hard-grade transparent engineering plastic and moulded over with anti-slip and cushion grade Elastomer for good comfort and non-slip grip. ", "Tip coated for long life ", "Hexagonal shaft screw driver shank for turning with a spanner, to open extra tight screws", "Available in both electrical insulated and un-insulated version", "Available in projected lengths of 100mm, 150mm, 200mm, 250mm & 300mm "],
        "application": ["For loosening and tightening Phillip and Slotted head screws"],
        "technical": [" Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome-plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "DG 6100",
                    "tip_size": "PH-2/S-6",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "DG 6150  ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "DG 6200 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "200 mm"
                },
                {
                    "model_no": "DG 6250 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "250 mm"
                },
                {
                    "model_no": "DG 6300 ",
                    "tip_size": "PH-2/S-6 ",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "300 mm"
                },
            ]
        },
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 202,
        "modelno": "SDK-600",
        "URLname": "6-in-1-multibit-reversible-screw-driver-set-6mm-Hex",
        "name": "6-in-1 Multibit Reversible Screw Driver Set (6mm Hex)",
        "img": "SDK-600.jpg",
        "features": ["6 assorted screw driver bits made out of high-grade alloy steel", "Tip coated for long life", "Handle made of tough engineering plastic", "Quick change metallic collet embedded within handle for easy and fast reversing of screw driver bit ", "Plastic rack / jacket to hold the set together"],
        "application": ["For loosening and tightening Phillip and Slotted head screws.", "Useful for Engineers/Professionals/Hobbyists"],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6, PH-1 and S-5, PH-0 and S-3 in reversible configuration", " Chrome plated finish"],
        "URL": "SCREW-DRIVER .webp",
        "category": "Screw Driver"
    },
    {
        "id": 203,
        "modelno": "SDK-600(i)",
        "URLname": "6-in-1-multibit-insulated-reversible-screw-driver-set-with-voltage-tester-Handle-6mm-Hex",
        "name": "6-in-1 Multibit Insulated Reversible Screw Driver Set with Voltage Tester Handle (6mm Hex)",
        "img": "SDK-600i.jpg",
        "features": ["3 assorted 2-in-1 configurations screw driver bits with electrical insulation ", "Screw driver bits made out of high-grade alloy steel ", "Tip coated for long life", "Handle with tester module to detect electrical voltage ", "Handle made of Angel-blue colour tough engineering plastic ", "Quick change metallic collet embedded within handle for easy and fast reversing of screw driver bit ", "Plastic rack / jacket to hold the set together "],
        "application": ["For loosening and tightening Phillip and Slotted head screws.", "Useful for Engineers/Professionals/Hobbyists"],
        "technical": ["Hardened and tempered ", "Head PH-2 and S-6, PH-1 and S-5, PH-0 and S-3 in reversible configuration", "Chrome plated finish"],
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 204,
        "modelno": "SR-6100",
        "URLname": "s-type-2-in-1-screw-driver-6mm-Hex",
        "name": "S-Type 2-in-1 Screw Driver (6mm Hex)",
        "img": "SR-6100.jpg",
        "features": ["Unique design 2-in-1 screw driver in S-type configuration ", "Screw driver bits made out of high-grade alloy steel", "Tip coated for long life", "Better leverage due to long length of shank"],
        "application": ["For loosening and tightening Phillip and Slotted head screws.", "Ideal for use in extremely short spaces "],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "SR-6100",
                    "tip_size": "PH-2/S-6",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "100 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 205,
        "modelno": "",
        "URLname": "2-in-1-reversible-screw-driver-with-hexagon-rod-and-extra-hard-tips-5mm-hex",
        "name": "2-in-1 Reversible Screw Driver with Hexagon Rod & Extra Hard Tips (5mm Hex)",
        "img": "R-5100.jpg",
        "features": ["Shank made out of high-grade alloy steel ", "Tip coated for long life", "Hexagon shaft screw driver shank for turning with a spanner for extra torque to open extra tight screws", "Available in projected lengths of 100mm and 150mm "],
        "application": ["For loosening and tightening Phillip and Slotted head screws. "],
        "technical": ["Hardened and tempered", "Head PH-1 and S-5 in reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "R 5100 ",
                    "tip_size": "PH-1/S-5",
                    "shank_size": "Hexagon 5mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "R 5100i",
                    "tip_size": "PH-1/S-5",
                    "shank_size": "Hexagon 5mm",
                    "projected_length_of_bit": "150 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 206,
        "modelno": "",
        "URLname": "2-in-1-reversible-screw-driver-with-hexagon-rod-and-extra-hard-hips-4mm-hex)",
        "name": "2-in-1 Reversible Screw Driver with Hexagon Rod & Extra Hard Tips (4mm Hex)",
        "img": "R-4075.jpg",
        "features": ["Shank made out of high-grade alloy steel ", "Tip coated for long life", "Hexagon shaft screw driver shank for turning with a spanner for extra torque to open extra tight screws", " Available in projected length of 75mm "],
        "application": ["For loosening and tightening Phillip and Slotted head screws. "],
        "technical": [" Hardened and tempered", "Head PH-0 and S-3 in reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "R 4075 ",
                    "tip_size": "PH-0/S-3",
                    "shank_size": "Hexagon 4mm",
                    "projected_length_of_bit": "75 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 207,
        "modelno": "",
        "URLname": "double-side-torx-screw-driver-in-hexagon-rod-and-extra-hard-tips",
        "name": "Double Side TORX Screw Driver in Hexagon Rod and Extra Hard Tips",
        "img": "TORX-SCREW-DRIVER.jpg",
        "features": ["Shank made out of high-grade alloy steel", " Tip coated for long life", "Two Torx heads same tip size in one screw driver tool", "Available in projected lengths of 75mm and 100mm "],
        "application": ["For loosening and tightening Torx screws."],
        "technical": ["Hardened and tempered ", "Torx heads machined as per International Standard", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size_both-ends", "label": "Tip Size Both Ends "},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "2xT6x75  ",
                    "tip_size_both-ends": "T6xT6 ",
                    "shank_size": "4mm",
                    "projected_length_of_bit": "74 mm"
                },
                {
                    "model_no": "2xT8x75  ",
                    "tip_size_both-ends": "T8xT8",
                    "shank_size": "4mm",
                    "projected_length_of_bit": "75 mm"
                },
                {
                    "model_no": "2xT8x150  ",
                    "tip_size_both-ends": "T8xT8 ",
                    "shank_size": "4mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "2xT10x75 ",
                    "tip_size_both-ends": "T10xT10",
                    "shank_size": " 4mm",
                    "projected_length_of_bit": "75 mm"
                },
                {
                    "model_no": "2xT10x150 ",
                    "tip_size_both-ends": "T10xT10",
                    "shank_size": "4mm",
                    "projected_length_of_bit": "150 mm"
                },
                {"model_no": " ", "tip_size_both-ends": "", "shank_size": "", "projected_length_of_bit": ""},
                {
                    "model_no": "2xT15x100 ",
                    "tip_size_both-ends": "T15xT15",
                    "shank_size": "5mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "2xT15x150 ",
                    "tip_size_both-ends": "T15xT15",
                    "shank_size": "5mm",
                    "projected_length_of_bit": "150 mm"
                },
                {"model_no": " ", "tip_size_both-ends": "", "shank_size": "", "projected_length_of_bit": ""},
                {
                    "model_no": "2xT20x100  ",
                    "tip_size_both-ends": "T20xT20",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "2xT20x150 ",
                    "tip_size_both-ends": "T20xT20",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "2xT25x100  ",
                    "tip_size_both-ends": "T25xT25 ",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "2xT25x150  ",
                    "tip_size_both-ends": "T25xT25 ",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "150 mm"
                },
                {
                    "model_no": "2xT30x100 ",
                    "tip_size_both-ends": "T30xT30 ",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "100 mm"
                },
                {
                    "model_no": "2xT30x150 ",
                    "tip_size_both-ends": "T30xT30 ",
                    "shank_size": "6mm",
                    "projected_length_of_bit": "150 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 208,
        "modelno": "STB-110 ",
        "URLname": "2-in-1-reversible-stubby-screw-driver",
        "name": "2-in-1 Reversible Stubby Screw Driver",
        "img": "STB-110.jpg",
        "features": ["Shank made out of high-grade alloy steel", "Tip coated for long life", "Stubby short screw driver handle made out of transparent engineering plastic", "Quick change metallic collet embedded within handle for fast and easy reversing of screw driver bit.", "Available in projected length of 35mm "],
        "application": ["For loosening and tightening Phillip and Slotted head screws."],
        "technical": ["Hardened and tempered", "Head PH-2 and S-6 in reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "STB-110 ",
                    "tip_size": "PH-2/S-6",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "35 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 209,
        "modelno": "TSTB-220",
        "URLname": "4-in-1-tee-oriented-stubby-screw-driver",
        "name": "4-in-1 Tee Oriented Stubby Screw Driver",
        "img": "TSTB-220.jpg",
        "features": ["Tee orientation in Stubby screw driver ", "Four screw driver heads in one tool", "Two-way reversible screw driver (fixed)", "Two-way reversible screw driver (reversible)", "Shank made out of high-grade alloy steel", "Tip coated for long life", "Tee orientation offers better leverage while operating by application of high torque, thereby taking care of the limitation of small handle in loosen/fasten tight screws ", "Stubby short screw driver handle made out of transparent engineering plastic ", "Quick change metallic collet embedded within handle for fast and easy reversing of screw driver bit.", "Available in projected length of 35mm "],
        "application": ["For loosening and tightening Phillip and Slotted head screws."],
        "technical": ["Hardened and tempered ", "Head PH-2 and S-6 in fixed and reversible configuration", "Chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "shank_size", "label": "Shank Size"},
                {"key": "projected_length_of_bit", "label": "Projected Length of Bit"},
            ],
            "rows": [
                {
                    "model_no": "TSTB-110 ",
                    "tip_size": "PH-2/S-6",
                    "shank_size": "Hexagon 6mm",
                    "projected_length_of_bit": "35 mm"
                },
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 210,
        "modelno": "STB-330",
        "URLname": "6-in-1-multibit-stubby-reversible-screw-driver-set",
        "name": "6-in-1 Multibit Stubby Reversible Screw Driver Set",
        "img": "STB-330.jpg",
        "features": ["6 Assorted screw driver bit made out of high-grade alloy steel", "Tip coated for long life", "Stubby short screw driver handle made out of transparent engineering plastic ", "Quick change metallic collet embedded within handle for fast and easy reversing of screw driver bit.", "Plastic rack/jacket to hold the tool set together"],
        "application": ["For loosening and tightening Phillip and Slotted head screws.", "Useful for Engineers/Professionals/Hobbyists "],
        "technical": ["Hardened and tempered", " Head PH-2 and S-6, PH-1 and S-5, PH-0 and S-3 in reversible configuration", "Chrome plated finish"],
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 211,
        "modelno": "MT-100",
        "URLname": "pocket-line-voltage-detector-tester",
        "name": "Pocket Line (Voltage Detector) Tester",
        "img": "MT-100.jpg",
        "features": ["Shank made out of 3mm hexagon high-grade alloy steel ", "Handle made out of high-grade transparent engineering plastic for good transparency, toughness and high electrical insulation ", "Unique neon bulb tester modular assemble concept", "High quality brass nut for good electrical conductivity for earthing that helps in good neon-bulb brightness", "Long and spline design for better grip"],
        "application": ["For detecting Voltage"],
        "technical": ["Hardened and tempered ", "Tip: 3mm ", "Shank chrome plated finish"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "tip_size", "label": "Tip Size"},
                {"key": "voltage_detection-range", "label": "Voltage Detection Range"},
            ],
            "rows": [
                {"model_no": "MT-100 ", "tip_size": "3mm Hex X 0.5mm", "voltage_detection-range": "0-500 Volts"},
            ]
        },
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },
    {
        "id": 212,
        "modelno": "SDK-777iC",
        "URLname": "6-multi-colour-insulated-screw-driver-bits-plus-1-poker-plus-handle-with-voltage-detector",
        "name": "6 Multi-colour Insulated Screw Driver Bits + 1 Poker + Handle with Voltage Detector",
        "img": "SDK-777-IC.jpg",
        "features": ["Screw driver kit made of transparent plastic box ", "Transparent handle made out of engineering plastic with tester / voltage detector", "7 Screw driver bits (6 multi-colour insulated screw driver bits + 1 poker)", "Multi-colour insulated bits for quick access to respective screw driver bit", "Screw driver bits are moulded on with sleeves for electrical insulation "],
        "application": ["For tightening and loosening screws, voltage detection, make holes with poker on wall "],
        "technical": ["Hardened and tempered ", "Screw driver bits - chrome plated finish"],
        "URL": "screwdriverbanner.webp",
        "category": "Screw Driver"
    },

    {
        "id": 189,
        "modelno": "MBD-100",
        "URLname": "magnetizer-booster-screw-holder-de-magnetizer",
        "name": "Magnetizer-Booster, Screw Holder, De-magnetizer",
        "img": "MBD-100.jpg",
        "features": ["Made out of high Silicon high Vanadium steel", "Brown Coated for surface protection", "Double ended Phillip head type"],
        "application": ["For electric/pneumatic power screw driver"],
        "technical": ["Hardened and tempered", "Tip size: PH0, PH1, PH2, PH3"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 190,
        "modelno": "",
        "URLname": "multi-purpose-magnetic-strip-holder",
        "name": "Multi-purpose Magnetic Strip-holder",
        "img": "Multi-purpose-Magnetic-Strip-holder.jpg",
        "features": ["Substantial magnetic power to hold tools and accessories", "Available in two sizes", "Can be easily fixed on walls with screw"],
        "application": ["For hanging tools in workshops, garages; kitchen knives, scissors, keys etc."],
        "technical": ["Model no. MS-12 - Length 12”", "Model no. MS-18 – Length 18”"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 191,
        "modelno": "",
        "URLname": "multiangle-welding-clamps",
        "name": "Multiangle Welding Clamps",
        "img": "Multiangle-Welding-Clamps.jpg",
        "features": ["Holding angles: 45°, 90°, 135°", " Multi-angle track welding", "Permanent suction/precise positioning/small and portable/various angles"],
        "application": ["Designed for holding work pieces when soldering or welding"],
        "technical": ["Holding force 4.5 Kg."],
        "dataTable": {
            "columns": [
                {"key": "Sl_No", "label": "Sl. No."},
                {"key": "model_no", "label": "Model No."},
            ],
            "rows": [
                {"Sl_No": "1", "model_no": "WMCL – 01",},
                {"Sl_No": "2", "model_no": "WMCL – 02"},
                {"Sl_No": "3", "model_no": "WMCL – 03"},
            ]
        },
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 192,
        "modelno": "KR-6045",
        "URLname": "key-ring-with-2-in-1-reversible-screw-driver",
        "name": "Key-ring with 2-in-1 Reversible Screw Driver",
        "img": "KR-6045.jpg",
        "features": ["Multipurpose key ring for holding keys up-to 6 nos. along-with a 2-in-1 reversible screw driver bit for normal/emergency use", "Available in two types screw driver bit – Torx and Phillip/Flat", "Quick reversing and ideal for working in short/small spaces"],
        "application": ["For loosening and tightening of screw heads – Torx, Phillip and Slotted"],
        "technical": ["Hardened and tempered", "Screw driver head PH2 and S6 / T8 and T15"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 193,
        "modelno": "CK-18",
        "URLname": "18mm-side-lock-snap-off-cutter-knife",
        "name": "18mm Side Lock Snap off Cutter Knife",
        "img": "CK-18.jpg",
        "features": ["Body made out of hard engineering plastic for smooth functioning and toughness", " Blade made out of JIS standard high-grade steel ", "One blade of 8 cut-off points", "Automatic blade lock for safety. Locks the blade at the appropriate desired length"],
        "application": ["For cutting paper, cardboard etc. "],
        "technical": ["Hardened and tempered for long life", "Blade length 110 mm", "Overall length 160 mm"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 194,
        "modelno": "",
        "URLname": "screw-drivers-range-display-stand",
        "name": "Screw-Drivers Range Display Stand",
        "img": "Screw-Drivers-Range-Display-Stand.jpg",
        "features": ["Accurate size slots for storing Multitec make screw drivers of various sizes and types"],
        "application": ["For storing screw drivers"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 195,
        "modelno": "TKB-12 & TKB-16",
        "URLname": "technician-toolkit-bag",
        "name": "Technician Toolkit Bag",
        "img": "TKB-12-TKB-16.jpg",
        "features": ["Made of high strength Tetron fabric to withstand weight", "Water resistant"],
        "application": ["Designed to suit the challenges of the technicians, who work or carry out installations, repairs and maintenance of machinery and equipment in the field", " Ideal for Electricians, Carpenters, Refrigeration Service Engineers, Plumbers, any other service engineer."],
        "technical": ["Available in two Models/Sizes: TKB-12 and TKB-16"],
        "URL": "mislanioustoolbanner.webp",
        "category": "MiscellaneousTools"
    },
    {
        "id": 213,
        "modelno": "TSK Series",
        "URLname": "tee-socket-wrench-high-torque",
        "name": "Tee Socket Wrench (High Torque)",
        "img": "TSK-Series.jpg",
        "features": ["Made out of high-grade alloy steel", "Multi-colour Tee handle sleeve for quick identification of Hex size", "Wide dimensions of Tee arm for better leverage ", "Mechanized inert gas shield welding for strong weld joints "],
        "application": ["For fastening and unfastening bolts and nuts "],
        "technical": ["Hardened and tempered ", "Nickel Chrome plated ", "Available sizes:"],
        "dataTable": {
            "columns": [
                {"key": "model_no", "label": "Model No."},
                {"key": "size", "label": "Size"},
            ],
            "rows": [
                {"model_no": "TSK-6", "size": "6 mm "},
                {"model_no": "TSK-7", "size": "7 mm "},
                {"model_no": "TSK-8", "size": "8 mm "},
                {"model_no": "TSK-9", "size": "9 mm "},
                {"model_no": "TSK-10", "size": "10 mm "},
                {"model_no": "TSK-11", "size": "11 mm "},
                {"model_no": "TSK-12", "size": "12 mm "},
                {"model_no": "TSK-13", "size": "13 mm "},
                {"model_no": "TSK-14", "size": "14 mm "},
                {"model_no": "TSK-16", "size": "16 mm "},
                {"model_no": "TSK-17", "size": "17 mm "},
                {"model_no": "TSK-19", "size": "19 mm "},
            ]
        },
        "URL": "TEE-SOCKET-WRENCH.jpg",
        "category": "TEE SOCKET WRENCH"
    },
    {
        "id": 214,
        "modelno": "",
        "URLname": "double-ended-bits-dyna-power-series",
        "name": "Double Ended Bits – DYNA Power Series",
        "subName": "Configuration: Phillip Head Type",
        "img": "dyna-power.jpg",
        "features": [" Made out of high Silicon high Vanadium steel", "Brown Coated for surface protection", "Double ended Phillip head type "],
        "application": ["For electric/pneumatic power screw driver"],
        "technical": ["Hardened and tempered", "Tip size: PH0, PH1, PH2, PH3"],
        "dataTable": {
            "columns": [
                {"key": "Sl_No", "label": "Sl. No."},
                {"key": "phillip_head_size", "label": "Phillip Head Size"},
                {"key": "overall_length", "label": "Overall Length"},
                {"key": "shank_size", "label": "Shank Size"},
            ],
            "rows": [
                {
                    "Sl_No": "1",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "50 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "2",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "65 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "3",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "75 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "4",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "100 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "5",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "150 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "6",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "200 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "7",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "250 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {
                    "Sl_No": "8",
                    "phillip_head_size": "PH0, PH1, PH2, PH3 ",
                    "overall_length": "300 mm",
                    "shank_size": "6.35 mm Hex "
                },
                {"Sl_No": "", "phillip_head_size": "", "overall_length": "", "shank_size": ""},
                {
                    "Sl_No": "9",
                    "phillip_head_size": "PH0, PH1, PH2",
                    "overall_length": "75 mm",
                    "shank_size": "5.0 mm Hex "
                },
                {
                    "Sl_No": "10",
                    "phillip_head_size": "PH0, PH1, PH2",
                    "overall_length": "100 mm",
                    "shank_size": "5.0 mm Hex "
                },
                {
                    "Sl_No": "11",
                    "phillip_head_size": "PH0, PH1, PH2",
                    "overall_length": "150 mm",
                    "shank_size": "5.0 mm Hex "
                },
                {
                    "Sl_No": "12",
                    "phillip_head_size": "PH0, PH1, PH2",
                    "overall_length": "200 mm",
                    "shank_size": "5.0 mm Hex "
                },
            ]
        },
        "URL": "powerbitsbanner.webp",
        "category": "Power Bits"
    },
    {
        "id": 215,
        "modelno": "",
        "URLname": "single-ended-type-bits",
        "name": "Single ended Type Bits",
        "subName": "Configuration: Single Ended Type",
        "img": "single-ended-type-bits.jpg",
        "features": ["Made out of high Silicon high Vanadium steel ", "Brown Coated for surface protection", "Single ended type"],
        "application": ["For electric/pneumatic power screw driver "],
        "technical": ["Hardened and tempered", "Shank size: 6.35 mm A/F Hex", "Screw Driver Tip Type:", "a) Phillip: PH-0, PH-1, PH-2, PH-3 ", "b) Slotted: S-3, S-5, S-6, S-8, S-9 ", "c) Torx: T-8, T-10, T-15, T-20, T-25, T-27, T-30, T-40", "d) Allen: A-3, A-4, A-5, A-6"],
        "URL": "powerbitsbanner.webp",
        "category": "Power Bits"
    },
    {
        "id": 216,
        "modelno": "",
        "URLname": "ball-ended-allen-bits",
        "name": "Ball Ended Allen Bits",
        "subName": "Configuration: Ball Ended Allen Type",
        "img": "ball-ended-allen-bits.jpg",
        "features": ["Made out of high Silicon high Vanadium steel", "Brown Coated for surface protection", "Ball ended Allen type"],
        "application": ["For electric/pneumatic power screw driver  "],
        "technical": ["Hardened and tempered ", "Shank size: 6.35 mm A/F Hex "],
        "dataTable": {
            "columns": [
                {"key": "Sl_No", "label": "Sl. No."},
                {"key": "head_type", "label": "Head Type "},
                {"key": "overall_length", "label": "Overall Length"},
            ],
            "rows": [
                {"Sl_No": "1", "head_type": "BA-4, BA-5, BA-6 ", "overall_length": "100 mm "},
                {"Sl_No": "2", "head_type": "BA-4, BA-5, BA-6 ", "overall_length": "150 mm "},
            ]
        },
        "URL": "powerbitsbanner.webp",
        "category": "Power Bits"
    },
    {
        "id": 217,
        "modelno": "",
        "URLname": "impact-screw-driver-bits ",
        "name": "Impact Screw Driver Bits ",
        "img": "impact-screw-driver-bits.jpg",
        "features": ["Made out of high Silicon high Vanadium steel "],
        "application": ["For electric/pneumatic power screw driver  "],
        "technical": ["Hardened and tempered "],
        "dataTable": {
            "columns": [
                {"key": "Sl_No", "label": "Sl. No."},
                {"key": "model_no", "label": "Model No."},
                {"key": "overall_length", "label": "Overall Length"},
            ],
            "rows": [
                {"Sl_No": "1", "model_no": "8-PH-40", "overall_length": "40mm"},
                {"Sl_No": "2", "model_no": "8-PH-40", "overall_length": "40mm"},
                {"Sl_No": "3", "model_no": "8-S8-40", "overall_length": "40mm"},
                {"Sl_No": "4", "model_no": "8-S9-40", "overall_length": "40mm"},
                {"Sl_No": "5", "model_no": "8-PH2-80", "overall_length": "80mm"},
                {"Sl_No": "6", "model_no": "8-PH3-80", "overall_length": "80mm"},
                {"Sl_No": "7", "model_no": "8-S8-80", "overall_length": "80mm"},
                {"Sl_No": "8", "model_no": "8-S9-80", "overall_length": "80mm"},
            ]
        },
        "URL": "powerbitsbanner.webp",
        "category": "Power Bits"
    },
    {
        "id": 218,
        "modelno": "ISD-100",
        "URLname": "impact-screw-driver-set ",
        "name": "Impact Screw Driver Set",
        "img": "ISD-100.jpg",
        "features": ["Body Made out of high-grade steel in hexagon shape bar for excellent grip hold ", "Consists of 8 impact resistant screw driver bits in two overall lengths of 40mm and 80mm", "Screw driver bits made out of high Silicon high Vanadium steel"],
        "application": ["For loosening and tightening the stubborn/jammed screws by the cam action of the screw driver that rotates up-to 15° on application of a hammer impact force on the rear end.", "Ideal tool for automobile, refrigeration and other mechanical workshops. "],
        "technical": ["Screw driver bits hardened and tempered for long life and impact tolerance", "Screw Driver Tips: PH2, PH3, S-8, S-9"],
        "URL": "powerbitsbanner.webp",
        "category": "Power Bits"
    },
    {
        "id": 219,
        "modelno": "GK-051",
        "URLname": "budding-and-grafting-knife",
        "name": "Budding & Grafting Knife",
        "img": "GK-051.jpg",
        "features": ["Blade made out of high-performance stainless steel", "Ultra sharp blade cut through thick wood.", "Ergonomic handle which fits between closed fingers with thumb rest for easy operation", "Foldable when not in use for safety"],
        "application": ["For budding and grafting "],
        "technical": ["Hardened and tempered ", "Overall length: 200 mm"],
        "URL": "cover-image-GK-051.jpg",
        "category": "BUDDING GRAFTING KNIFE"
    },
];
