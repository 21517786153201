import React, {useEffect, useState} from "react";
import {Products} from "../Products";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import ImgSlider from "../SubPages/ImgSlider";
import Nav from "../pages/Nav.js";
import "../../css/ourproduct.css";

const TeeSocketWrench = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;

    const wrenchProducts = Products.filter(
        (product) => product.category === "TEE SOCKET WRENCH"
    );

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const productsToDisplay = wrenchProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(wrenchProducts.length / productsPerPage);

    const handlePrevPage = () => setCurrentPage(currentPage - 1);
    const handleNextPage = () => setCurrentPage(currentPage + 1);
    const handlePageNumber = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Tee Socket Wrench</title>
                <link rel="canonical" href="https://multitectools.com/teesocketwrench"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://multitectools.com/"/>
                <meta property="og:title" content="Multitec Tools - Tee Socket Wrench"/>
                <meta property="og:description"
                      content="Tee Socket Wrenches offered by Multitec Industries from Gurgaon, Haryana, India."/>
                <meta property="og:image" content="https://metatags.io/images/meta-tags.png"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content="Multitec Tools - Tee Socket Wrench"/>
                <meta property="twitter:description"
                      content="Tee Socket Wrenches offered by Multitec Industries from Gurgaon, Haryana, India."/>
            </Helmet>
            <Nav/>
            <div className="sm:py-12 py-32" style={{backgroundColor: "whitesmoke"}}>
                <ImgSlider/>
                <div
                    style={{minHeight: "450px"}}
                    className={`${
                        productsToDisplay.length === 1 ? "block" : "flex flex-wrap justify-center items-center"
                    } text-center mt-5 bg-white rounded mb-10 md:mx-20 mx-10`}>
                    <h1 className="text-3xl font-medium  pt-5 pb-10">Tee Socket Wrench</h1>
                    <div className="flex flex-wrap justify-center">
                        {productsToDisplay.map((product) => (
                            <div key={product.id} className="px-5 border-b-2">
                                <div className="glassBox">
                                    <div className="glassBox__imgBox">
                                        <Link to={`/teesocketwrench-productdetail/${product.URLname}`}>
                                            <img className="productcardimg" loading="lazy" src={`/image/${product.img}`}
                                                 alt=""/>
                                        </Link>
                                    </div>
                                </div>
                                <h2 className="truncate text-sm mt-2 w-60">{product.modelno}</h2>
                                <Link to={`/teesocketwrench-productdetail/${product.URLname}`}>
                                    <button
                                        className="px-4 py-2 mt-5 bg-red-700 text-white text-lg rounded shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] transition-all hover:translate-y-1"
                                        style={{marginBottom: "20px"}}>
                                        View Details
                                    </button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex items-center justify-evenly mt-10">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
                    {Array.from({length: totalPages}, (_, i) => i + 1).map((pageNumber) => (
                        <button key={pageNumber} onClick={() => handlePageNumber(pageNumber)}
                                className={`mx-1 ${pageNumber === currentPage ? "bg-blue-500 text-white" : "text-black"}`}>
                            {pageNumber}
                        </button>
                    ))}
                    <button onClick={handleNextPage} disabled={indexOfLastProduct >= Products.length}>Next</button>
                </div>
            </div>
        </>
    );
};

export default TeeSocketWrench;
